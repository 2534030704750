<template>
  <a-select
    show-search
    :placeholder="$t('分销商名称')"
    option-filter-prop="children"
    style="width: 100%"
    v-model="value"
    :filter-option="filterOption"
    @change="handleChange"
  >
    <a-select-option :value="item" v-for="(item, index) in list" :key="index">
      {{ item }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '@/service/axios'
import api from '@/service/api'
export default {
  props: {
    shopCode: {
      type: String,
    },
    platform: {},
    name: {},
  },
  data() {
    return {
      value: undefined,
      list: [],
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('update:name', value)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async getDistributorListNew() {
      let res = await http({
        url: api.getDistributorListNew,
        data: {
          supplierCode: this.shopCode,
          status: 'normal',
          pageNo: 1,
          pageSize: 1000,
        },
      })
      this.list = res.result.distribution_list?.map((item) => item.distributor_name) || []
    },
  },
  watch: {
    shopCode: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getDistributorListNew()
        }
      },
    },
    name(val) {
      this.value = val
    },
  },
}
</script>
